import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren, AfterViewInit, HostListener } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { ToastrService } from 'ngx-toastr';
import { DBAModel, copyriteYear } from '../../models/dbamodel';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
declare var require: any
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-online-ysfloorplans',
  templateUrl: './online-ysfloorplans.component.html',
  styleUrls: ['./online-ysfloorplans.component.css']
})
export class OnlineYsfloorplansComponent implements OnInit, AfterViewInit {
  progressbar: NgProgressRef;
  managementCompanyId: number;
  dbaId: number;
  public dba: DBAModel;
  public unitTypesModel = [];
  public floorplansModel = [];
  fpModal: BsModalRef;
  curUnit;
  isSticky = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 20;
  }

  pgLoaded = false;
  show3D = false;
    readonly copyriteYear = copyriteYear;
    themeURLs = [];
    vrsn = new Date().toISOString().slice(0, 10);
    @ViewChildren('flrstepper') public flrSteppers: QueryList<ElementRef>;
  constructor(
    public apiService: GlobalService,
    private route: ActivatedRoute,
    public router: Router,
    public progress: NgProgress,
    private toaster: ToastrService,
      private modalService: BsModalService,
  private sanitizer: DomSanitizer) {
    this.dba = new DBAModel();
  }
  ngOnInit() {
    this.apiService.showPageLoader();
      this.progressbar = this.progress.ref();
      this.progressbar.start();
      this.generateThemeURLs();
    this.modalService.onHide.subscribe((reason: string) => {
      this.show3D = false;
    });
    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.dbaId = +params.get('id');
        if (this.dbaId > 0) {
          this.GetDBADetails();     
        }
        else {
          this.toaster.error("There is an error with the provided URL. Please verify.", "Oops!");
        }
      }
    );
    window.scroll(0, 0);
  }
  GetDBADetails() {
    this.progressbar.start();
    this.apiService.getDataById("api/DBA/GetDBADetails", this.dbaId).subscribe(result => {
      if (result.dbaId > 0) {
        this.dba = result;
        //$('link[rel=stylesheet][href*="theme"]').remove();
        //require("style-loader!styles/theme" + this.dba.dbaThemeId + ".css");
        this.managementCompanyId = this.dba.managementCompanyId;
        this.GetFloorplans();
      }
      else {
        this.progressbar.complete();
        this.pgLoaded = true;
        this.toaster.error("There is an error with the provided URL. Please verify.", "Oops!");
      }
    },
      err => {
        this.progressbar.complete();
        this.pgLoaded = true;
        this.toaster.error(err.error.Message, "Oops!");
      }
    );
  }
  GetFloorplans() {
    this.apiService.putDataByIds("api/DBA/IsYieldStarEnabled", this.dbaId,this.managementCompanyId).subscribe(result => {
      if (result.StatusCode == 200) {
        this.router.navigate(['/floorplans/' + this.dbaId]);
        
      }
      else {
        this.apiService.getDataById("api/DBA/GetYSFloorPlans", this.dbaId).subscribe(result => {
          var dataresult = result;
          this.unitTypesModel = dataresult.unitTypeModel;
          this.floorplansModel = dataresult.hotSheetUnitsModel;
          
          this.pgLoaded = true;
          this.progressbar.complete();
        },
          err => {
            this.progressbar.complete();
            this.pgLoaded = true;
            this.toaster.error(err.error.Message, "Oops!");
            
          }
        );
      }
    },
      err => {
        this.progressbar.complete();
        this.pgLoaded = true;
        this.toaster.error(err.error.Message, "Oops!");
      }
    );    
  }
  filterItemsOfType(dbaUnitTypeId) {
    var result = this.floorplansModel.filter(x => x.dbaUnitTypeId == dbaUnitTypeId);
    return result;
  }
  showFPModal(flrPModal, unitIndex) {
    this.curUnit = this.unitTypesModel[unitIndex];
    this.fpModal = this.modalService.show(flrPModal, { class: 'modal-lg' });
  }
  hideFPModal() {
    if (this.fpModal)
      this.fpModal.hide();
  }
  setFPImage(eve: any) {
    this.show3D = !this.show3D;

    }
    generateThemeURLs() {
        this.themeURLs = [];
        for (let i = 0; i < 8; i++) {
            this.themeURLs.push(this.sanitizer.bypassSecurityTrustResourceUrl('/assets/styles/theme' + (i + 1) + '.css?v=' + this.vrsn));
        }
    }
    public scrollRight(indx): void {
        let curDiv = this.flrSteppers.toArray()[indx];
      try {
        curDiv.nativeElement.scrollTo({ left: (curDiv.nativeElement.scrollLeft + 220), behavior: 'smooth' });
      } catch (e) {
        curDiv.nativeElement.scrollLeft = curDiv.nativeElement.scrollLeft + 220;
      }
    }

    public scrollLeft(indx): void {
        let crrDiv = this.flrSteppers.toArray()[indx];
      try {
        crrDiv.nativeElement.scrollTo({ left: (crrDiv.nativeElement.scrollLeft - 220), behavior: 'smooth' });
      } catch (e) {
        crrDiv.nativeElement.scrollLeft = crrDiv.nativeElement.scrollLeft - 220;
      }
    }
    getUnitItemsCount(dbaUnitTypeId) {
        return this.floorplansModel.filter(x => x.dbaUnitTypeId == dbaUnitTypeId).length;
  }
  ngAfterViewInit() {
    this.apiService.hidePageLoader();
  }
}
