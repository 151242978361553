import { Injectable, } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DBAModel } from '../models/dbamodel';
import { Router } from '@angular/router';
declare var jquery: any;
declare var $: any;
declare var gtag: Function;
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  //baseUrl = 'https://residentapistestdrive.yottareal.com/';
  //baseUrl = 'https://residentapisdemo.yottareal.com/';
   baseUrl = 'https://residentapis.yottareal.com/';
  // baseUrl = 'http://residentapistest.yottareal.com/';
// baseUrl = 'http://localhost/Cybersoft.YottaResident.WebAPIs/';
  constructor(private httpClient: HttpClient, private router: Router) {
  }

  private dbaData: Subject<DBAModel> = new BehaviorSubject<DBAModel>(new DBAModel());
  get dbaData$() {
    return this.dbaData.asObservable();
  }
  addDBAData(data: DBAModel) {
    this.dbaData.next(data);
  }
  private mgmtData: Subject<any> = new BehaviorSubject<any>(new Object());
  get mgmtData$() {
    return this.mgmtData.asObservable();
  }
  addMgmtData(data: any) {
    this.mgmtData.next(data);
  }

  getData(methodName): Observable<any> {
    return this.httpClient.get(this.baseUrl + methodName)
      .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.log('Client Side Error :', errorResponse.error.message);
    } else {
      console.log('Server Side Error :', errorResponse);
    }
    return throwError(errorResponse);
  }

  GetFloorPlansReport(methodName, id: number, id2:boolean): Observable<any> {
    return this.httpClient.get(`${this.baseUrl + methodName}/${id}/${id2}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'blob'
    })
      .pipe(catchError(this.handleError));
  }
  getDataById(methodName, id: number): Observable<any> {
    return this.httpClient.get(`${this.baseUrl + methodName}/${id}`)
      .pipe(catchError(this.handleError));
  }
  getDataByIds(methodName, id1: number, id2: number): Observable<any> {
    return this.httpClient.get(`${this.baseUrl + methodName}/${id1}/${id2}`)
      .pipe(catchError(this.handleError));
  }
  getDataByMultipleIds(methodName, id1: number, id2: string, id3: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl + methodName}/${id1}/${id2}/${id3}`)
      .pipe(catchError(this.handleError));
  }
  getInitData(mgmtId): Observable<any[]> {
    let statesData = this.getData('api/Common/GetAllStates');
    let regionsData = this.getDataById('api/Common/GetAllRegionsByManagmentCompanyId', mgmtId);
    let DBAsData = this.getDataById('api/Common/GetAllDBAsByManagmentCompanyId', mgmtId);
    return forkJoin([statesData, regionsData, DBAsData]);
  }
  postData(methodName, Body): Observable<any> {
    return this.httpClient.post(this.baseUrl + methodName, JSON.stringify(Body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
      .pipe(catchError(this.handleError));
  }

  putData(methodName, Body): Observable<void> {
    return this.httpClient.put<void>(`${this.baseUrl + methodName}/${Body.id}`, JSON.stringify(Body), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
      .pipe(catchError(this.handleError));
  }
  putDataById(methodName, id1: number): Observable<any> {
    return this.httpClient.put<void>(`${this.baseUrl + methodName}/${id1}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }
  putDataByIds(methodName, id1: number, id2: number): Observable<any> {
    return this.httpClient.put<void>(`${this.baseUrl + methodName}/${id1}/${id2}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }

  deleteData(methodName, id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl + methodName}/${id}`)
      .pipe(catchError(this.handleError));
  }
  hidePageLoader() {
    $('#page-loader').addClass('dimissloader');
  }
  showPageLoader() {
    $('#page-loader').removeClass('dimissloader');
  }
  twoDigit(number) {
    var twodigit = number >= 10 ? number : "0" + number.toString();
    return twodigit;
  }
  convertDate(dt) {
    return this.twoDigit((dt.getMonth() + 1)).toString() + "/" + this.twoDigit(dt.getDate()).toString() + "/" + dt.getFullYear().toString();
  }
  printContent(printAreaId) {
    const printContent = document.getElementById(printAreaId);
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    try {
      //WindowPrt.document
      WindowPrt.document.execCommand('print', false, null);
      WindowPrt.document.close();
    } catch (e) {
      WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();
    }

    WindowPrt.close();
  }

  googleAnalyticsDBA(googleAnalyticsKey) {    
    try {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsKey;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` + googleAnalyticsKey + `', {'send_page_view': false});
      `;
      document.head.appendChild(script2);
    } catch (ex) {
      console.error('Google Analytics Exception: Error appending google analytics');
      console.error('Google Analytics Exception:' + ex);
    }
  }

  googleAnalytics_GTM_DBA(GTM_Key) {
    try {
      const script2 = document.createElement('script');
      script2.innerHTML = `
        (function (w, d, s, l, i) {
          w[l] = w[l] || []; w[l].push({
          'gtm.start':
          new Date().getTime(), event: 'gtm.js'
          }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer','` + GTM_Key + `');`;
      document.head.appendChild(script2);
    } catch (ex) {
      console.error('Google Analytics Exception: Error appending googleAnalytics_GTM_DBA');
      console.error('Google Analytics Exception:' + ex);
    }
  }

  googleAnalytics_noscript_DBA(GKey) {
    try {
      const script2 = document.createElement('script');
      script2.innerHTML = `
         $(document).ready(function() {
        $(document.body).prepend('<noscript><iframe src=https://www.googletagmanager.com/ns.html?id=` + GKey + ` height=0 width=0 style=display:none;visibility:hidden></iframe></noscript>');
});`;
      document.body.appendChild(script2);
    } catch (ex) {
      console.error('Google Analytics Exception: Error googleAnalytics_noscript_DBA');
      console.error('Google Analytics Exception:' + ex);
    }
  }

}
