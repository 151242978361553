export class DBAModel {
  managementCompanyId: number
  managementCompanyName: string
  logo: string
  portalRegionId: number
  bedRooms: string
  bathRooms: string
  minRent: any
  maxRent: any

  dbaId: number
  dbaName: string
  dbalogo: string
  email: string
  address1: string
  address2: string
  city: string
  stateCode: string
  stateName: string
  zip: string
  phoneNumber: string
  phoneNumberFormatted: string
  faxNumber: string
  isWeeklyHours: boolean
  monOfficeHours: string
  tueOfficeHours: string
  wedOfficeHours: string
  thuOfficeHours: string
  friOfficeHours: string
  montoFriOfficeHours: string
  satOfficeHours: string
  sunOfficeHours: string
  latitude: any
  longitude: any
  distanceInMiles: any
  homeThumbnail_Location: string

  minBedRooms: number
  maxBedRooms: number
  minBathRooms: any
  maxBathRooms: any
  dbaThemeId: number
  dbaContent_Header: string
  dbaContent_Text: string
  homeImage1_Location: string
  homeImage1_TagLine_Header: string
  homeImage1_TagLine_Text: string
  homeImage1_TagLine_ClickUrl: string
  homeImage2_Location: string
  homeImage2_TagLine_Header: string
  homeImage2_TagLine_Text: string
  homeImage2_TagLine_ClickUrl: string
  homeImage3_Location: string
  homeImage3_TagLine_Header: string
  homeImage3_TagLine_Text: string
  homeImage3_TagLine_ClickUrl: string
  homeImage4_Location: string
  homeImage4_TagLine_Header: string
  homeImage4_TagLine_Text: string
  homeImage4_TagLine_ClickUrl: string
  homeImage5_Location: string
  homeImage5_TagLine_Header: string
  homeImage5_TagLine_Text: string
  homeImage5_TagLine_ClickUrl: string
  homeImage6_Location: string
  homeImage6_TagLine_Header: string
  homeImage6_TagLine_Text: string
  homeImage6_TagLine_ClickUrl: string
  homeImage7_Location: string
  homeImage7_TagLine_Header: string
  homeImage7_TagLine_Text: string
  homeImage7_TagLine_ClickUrl: string
  head_Meta_Title: string
  head_Meta_Description: string
  head_Meta_Keywords: string
  youTube_TV_Header: string
  youTube_TV_Text: string
  youTube_Mgmt_URL: string
  youTube_Property1_Logo_Location: string
  youTube_Property1_Detail: string
  youTube_Property1_URL: string
  youTube_Property2_Logo_Location: string
  youTube_Property2_Detail: string
  youTube_Property2_URL: string
  youTube_Property3_Logo_Location: string
  youTube_Property3_Detail: string
  youTube_Property3_URL: string
  show_Youtube_Tv: boolean
  is_PhoneNo_Text_Enabled: boolean
  managementCompany_FooterLogo_Location: string
  copyRightInfo: string
  portalTrackingPhoneNumber: string
  isYieldStarEnabled: boolean
  emergencyInfo: string
  leaseHawkDBAId: number
  HomePageSubImage: string  
  dbaFacebook_URL: string
  dbaTwitter_URL: string
  dbaGooglePlus_URL: string
  dbaLinkedIn_URL: string
  dbaInstagram_URL: string
  isViewVirtualTour: boolean
  virtualTourPath: string
  googleAnalyticsKey: string
  gtmKey: string
  googleSiteVerification: string
  virtualLeasingPhone: string
  gmapsKey: string
}
export const copyriteYear = new Date().getFullYear();
export const residentPortalURL = 'https://resident.yottareal.com';
//export const residentPortalURL = 'https://webportaldemo.yottareal.com';
//export const residentPortalURL = 'https://webportaltestdrive.yottareal.com';
