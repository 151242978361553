import { Component, OnInit, AfterViewInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { ToastrService } from 'ngx-toastr';
import { DBAModel, residentPortalURL } from '../../models/dbamodel';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
@Component({
  selector: 'app-paperless-subscription',
  templateUrl: './paperless-subscription.component.html',
  styleUrls: ['./paperless-subscription.component.css']
})
export class PaperlessSubscriptionComponent implements OnInit, AfterViewInit {
  progressbar: NgProgressRef;
  applicantId: number;
  email: string;
  appKey: string;
  applicantDetails: any;
  isFailed: boolean;
  isEnrolled: boolean;
  isSuccess: boolean;
  public dba: DBAModel;
  readonly resPortalURL = residentPortalURL+'/login/yubs';
  constructor(
    public apiService: GlobalService,
    private route: ActivatedRoute,
    public router: Router,
    public progress: NgProgress,
    private toaster: ToastrService, ) {
    this.dba = new DBAModel();
  }

  ngOnInit() {
    this.progressbar = this.progress.ref();
    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.progressbar.start();
        this.applicantId = +params.get('applicantId');
        this.email = params.get('email');
        this.appKey = params.get('key');
        if (this.applicantId>0 && this.email != '' && this.appKey != '') {
          this.apiService.getDataByMultipleIds("api/DBA/GetApplicationEncodedValues", this.applicantId, this.email, this.appKey).subscribe(result => {
            this.applicantDetails = result;
            if (this.applicantDetails != null) {
              if (this.applicantDetails.isAlreadyEnrolled) {
                this.isFailed = false;
                this.isSuccess = false;
                this.isEnrolled = true;
                this.progressbar.complete();
              }
              else {
                this.apiService.putDataByIds("api/DBA/IsAllowedToSubscribeForPaperless", this.applicantDetails.dbaId, this.applicantDetails.applicantId).subscribe(result => {
                  if (result.StatusCode == 200) {
                    this.apiService.putDataById("api/DBA/InsertPaperLessEnrollmentDetails", this.applicantDetails.applicantId).subscribe(result => {
                      if (result.StatusCode == 200) {
                        this.applicantDetails.email = this.applicantDetails.email.substr(this.applicantDetails.email.indexOf('@') - 3);
                        this.isFailed = false;
                        this.isSuccess = true;
                        this.progressbar.complete();
                        
                      }
                      else {
                        this.isFailed = true;
                        this.isSuccess = false;
                        this.progressbar.complete();
                        
                      }
                    },
                      err => {
                        this.progressbar.complete();
                        this.toaster.error(err.error.Message, "Oops!");
                        this.isFailed = true;
                        this.isSuccess = false;
                        this.isEnrolled = false;
                        
                      });
                  }
                  else {
                    //already activated
                    this.isFailed = true;
                    this.isSuccess = false;
                    this.progressbar.complete();
                    
                  }
                },
                  err => {
                    this.progressbar.complete();
                    this.toaster.error(err.error.Message, "Oops!");
                    this.isFailed = true;
                    this.isSuccess = false;
                    this.isEnrolled = false;
                    
                  });
              }
            }
            else {
              this.isFailed = true;
              this.isSuccess = false;
              this.isEnrolled = false;
              
              this.progressbar.complete();
            }
            
          },
            err => {
              this.progressbar.complete();
              this.toaster.error(err.error.Message, "Oops!");
              this.isFailed = true;
              this.isSuccess = false;
              this.isEnrolled = false;
              
            }
          );          
        }
        else {
          this.progressbar.complete();
          this.isFailed = true;
          this.isSuccess = false;
          this.isEnrolled = false;
          
        }        
      });  
  }
  ngAfterViewInit() {
    this.apiService.hidePageLoader();
  }
}
