import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { ToastrService } from 'ngx-toastr';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'unsubscribe-email',
  templateUrl: './unsubscribe-email.html',
  styleUrls: ['./unsubscribe-email.css']
})
export class UnsubscribeEmailComponent implements OnInit {
  progressbar: NgProgressRef;
  constructor(
    public apiService: GlobalService,
    private toaster: ToastrService,
    public progress: NgProgress,
    private route: ActivatedRoute,
    public router: Router,) { }
  unsub = {
    UnsubscribeEmailId: 0,
    UnsubscribeEmail: "",
    Reasons: "",
    Description: ""
  }
  emailId: any;

  reasons = [];
  ngOnInit() {    
    this.route.queryParams.subscribe(
      (params: any) => {
        this.emailId = params.emailid;
      }
    );
    this.progressbar = this.progress.ref();
    this.getUnsubscribeEmailReasons();
  }
  getUnsubscribeEmailReasons() {
    this.apiService.getData("api/DBA/GetUnsubscribeEmailReasons").subscribe(result => {
      this.reasons = result;
    },
      err => {
        this.toaster.error(err.error.Message, "Oops!");
      }
    );
  }
  public unsubscribeSubmit() {
    this.unsub.UnsubscribeEmail = this.emailId;
    this.progressbar.start();
    this.apiService.postData("api/DBA/UnsubscribeEmailsInsert", this.unsub)
      .subscribe(result => {
        this.toaster.success("<p><h1> You've been unsubscribed.</h1></p><p>You won't receive any more emails from Yottareal.</p>", "Success");
        this.progressbar.complete();
      },
        err => {
          this.progressbar.complete();
          this.toaster.error(err.error, "Oops!");
        }
      );
  }
  onSelectedReasonChange(res) {
    this.unsub.Reasons = res;
  }
}
