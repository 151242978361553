import { Injectable } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Injectable()
export class GlobalSpinnerService {

  spinner;
  delayInMilliseconds = 10;
  constructor() { }

  startSpinner()
  {
    $('#cs-global-busy').show();
    //this.spinner = document.getElementById('cs-global-spinner');
    //this.spinner.style.display = '';
  }

  stopSpinner()
  {
    $('#cs-global-busy').hide();
    //setTimeout(function () { $('#cs-global-busy').hide(); }, this.delayInMilliseconds);
    //this.spinner = document.getElementById('cs-global-spinner');
    //this.spinner.style.display = 'none';
  }

  startOverlay() {
    $('#cs-overlay').show();
    //this.spinner = document.getElementById('cs-global-spinner');
    //this.spinner.style.display = '';
  }

  stopOverlay() {
    $('#cs-overlay').hide();
    //this.spinner = document.getElementById('cs-global-spinner');
    //this.spinner.style.display = 'none';
  }

}
