import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnlineFloorplansComponent } from './pages/online-floorplans/online-floorplans.component';
import { OnlineYsfloorplansComponent } from './pages/online-floorplans/online-ysfloorplans.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { UnsubscribeEmailComponent } from './pages/unsubscribe-email/unsubscribe-email';
import { PaperlessSubscriptionComponent } from './pages/paperless-subscription/paperless-subscription.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RedirectComponent } from './pages/redirect/redirect.component';

const routes: Routes = [
  { path: '', redirectTo: 'mgmt', pathMatch: 'full' },
  { path: 'mgmt', loadChildren: './management/management.module#ManagementModule' },
  { path: 'dba', loadChildren: './dba/dba.module#DbaModule' },
  { path: 'floorplans/:id', component: OnlineFloorplansComponent },
  { path: 'ysfloorplans/:id', component: OnlineYsfloorplansComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'unsubscribe-email', component: UnsubscribeEmailComponent },
  { path: 'paperless-subscription/:applicantId/:email/:key', component: PaperlessSubscriptionComponent },
  { path: 'redirect/:path/:id', component: RedirectComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]  
})
export class AppRoutingModule { }
