
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WebApiUrl } from './url';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GlobalAPIService {
  getUserProfile() {
    this.get_All_Service('GetUserProfile').
      subscribe(
        data => {
          window.localStorage['userProfileData'] = JSON.stringify(data.data);
        },
        (err: any) => {
          console.log("error", err.error);
        });
  }
  PaymentConfigurationData: any;
  GetPaymentConfigurationByTenantUnitId() {
    this.get_All_Service('GetPaymentSettingsByTenantUnitId').
      subscribe(
        result => {
          this.PaymentConfigurationData = result.data;

        },
        (err: any) => {
          console.log("error", err);
        })
  }


  constructor(private _http: HttpClient) {
  }
  private handleError(operation: String) {
    return (err: any) => {
      if (err.error.Message == null) {
        err.error.Message = "Sorry, we're unable to process your request. Please try again later.";
      }
      return observableThrowError(err);
    }
  }

  private setHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Accept", "application/json")
      .set('Inception', window.location.href)
      .set('Authorization', 'Bearer ' + window.localStorage['access_token']);

    return headers;
  }

  private setWTCHeaders(IPAddress): HttpHeaders {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Accept", "application/json")
      .set('Inception', window.location.href)    
      .set("IPAddress", IPAddress)
      .set("IsWebTimeClock", "true")

    return headers;
  }

  private setWOHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .set("Content-Disposition", "form-data")
      .set('Inception', window.location.href)
      .set('Authorization', 'Bearer ' + window.localStorage['access_token']);

    return headers;
  }
  getLoginToken(userdata,ip) {
    if (userdata.username !== '' && userdata.password !== '') {
      if (userdata.unitId == null)
        userdata.unitId = 0;
      const json = 'username=' + userdata.username + '&password=' + userdata.password + '&scope=' + userdata.unitId + "&grant_type=password";
      return this._http.post(WebApiUrl + 'token', json, {
        headers: this.setWTCHeaders(ip)
      })
        .pipe(
          catchError(this.handleError('getData'))
        );
    }
  }
  get_All_Service(APIName): Observable<any> {
    return this._http.get(WebApiUrl + APIName,
      {
        headers: this.setHeaders()
      })
      .pipe(
        catchError(this.handleError('getData'))
      );
  }

  get_put_by_Params_Service(APIName, Body) {
    const bodyString = JSON.stringify(Body); // Stringify payload

    const apiURL = WebApiUrl + APIName;
    return this._http.put(apiURL, bodyString, {
      headers: this.setHeaders()
    })
      .pipe(
        catchError(this.handleError('getData'))
      );
  }

  get_post_by_Params_Service(APIName, Body) {
    const bodyString = JSON.stringify(Body); // Stringify payload
    const apiURL = WebApiUrl + APIName;
    return this._http.post(apiURL, bodyString, {
      headers: this.setHeaders()
    })
      .pipe(
        catchError(this.handleError('getData'))
      );
  }

  post_WO_Params_Service(APIName, formData) {
    const apiURL = WebApiUrl + APIName;
    return this._http.post(apiURL, formData, {
      headers: this.setWOHeaders()
    })
      .pipe(
        catchError(this.handleError('getData'))
      );
  }

  get_delete_by_Params_Service(APIName) {
    const apiURL = WebApiUrl + APIName;
    return this._http.delete(apiURL, {
      headers: this.setHeaders()
    })
      .pipe(
        catchError(this.handleError('getData'))
      );
  }
  delete_by_Body_Service_WithPost(APIName, Body) {
    const bodyString = JSON.stringify(Body); // Stringify payload
    const apiURL = WebApiUrl + APIName;
    return this._http.post(apiURL, bodyString, {
      headers: this.setHeaders()
    })
      .pipe(
        catchError(this.handleError('getData'))
      );
  }
  GetYUBSStatementReport(vals) {
    const apiURL = WebApiUrl + 'GetStatement?DBABillingPeriodId=' + vals;
    return this._http.get(apiURL,
      {
        headers: this.setHeaders(),
        responseType: 'blob'
      })
      .pipe(
        catchError(this.handleError('getData'))
      );
  }

  //getDataByMultipleIds(methodName, id1: string, id2: Date): Observable<any> {    
  //  return this._http.get(`${WebApiUrl + methodName}/${id1}/${id2}`)
  //    .pipe(catchError(this.handleError));
  //}

  get_All_ServiceByParams(APIName, params): Observable<any> {
    const paramsString = JSON.stringify(params);
    return this._http.post(WebApiUrl + APIName, paramsString,
      {
        headers: this.setHeaders()
      })
      .pipe(
        catchError(this.handleError('getData'))
      );
  }

  getDataByMultipleIds(APIName, id1: string, id2: string) {
    const apiURL = WebApiUrl + APIName + '/' + id1 + '/' + id2;
    return this._http.get(apiURL,
      {
        headers: this.setHeaders()
      })
      .pipe(
        catchError(this.handleError('getData'))
      );
  }

  getById(APIName, id) {
    const apiURL = WebApiUrl + APIName + '/' + id;
    return this._http.get(apiURL,
      {
        headers: this.setHeaders()
      })
      .pipe(
        catchError(this.handleError('getData'))
      );
  }

  public getIPAddress() {
    return this._http.get("https://jsonip.com");
  }

  public getGeoLocation1(ip) {

    return this._http.get("https://api.ipgeolocation.io/ipgeo?apiKey=" + localStorage['gmapsKey'] +"&ip=" + ip);
  }

  getGEOLocation(ip) {

    //let headers = new HttpHeaders();
    let url = "https://api.ipgeolocation.io/ipgeo?apiKey=" + localStorage['gmapsKey'] +"&ip=" + ip;
    return this._http
      .get(url)
      .pipe(
        catchError(this.handleError)
      );
  } 

  private setIPHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Accept", "application/json")
      .set("Access-Control-Allow-Origin", "*")      
      .set("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT")
      .set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization")
    return headers;
  }
}
