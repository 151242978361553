import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private toaster: ToastrService, 
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        var dbaId = +params.get('id');
        var path=params.get('path');
        if (dbaId > 0 && path!='') {
          localStorage['DBAId'] = dbaId;
          this.router.navigate(['/dba/'+path]);
        }
        else {
          this.toaster.error("There is an error with the provided URL. Please verify.", "Oops!");
          this.router.navigate(['/mgmt/home']);
        }
      }
    );
  }

}
