import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AlertModule, CarouselModule, ProgressbarModule, BsDropdownModule, BsDatepickerModule, ModalModule } from 'ngx-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxMaskModule } from 'ngx-mask';
import { SubmitIfValidDirective } from '../pipes/submit-if-valid.directive';
import { AutofocusDirective } from '../pipes/auto-focus.directive';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { PhoneFormatPipe } from '../pipes/phone-format.pipe';
import { NgxPrintModule } from 'ngx-print';
import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';

@NgModule({
  declarations: [
    SubmitIfValidDirective,
    AutofocusDirective,
    TruncatePipe,
    SafeHtmlPipe,
    PhoneFormatPipe
  ],
  imports: [
    CommonModule,
    FormsModule,    
    CarouselModule.forRoot(),
    AlertModule.forRoot(),
    ProgressbarModule.forRoot(),
    BsDropdownModule.forRoot(),
    Ng5SliderModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDUE2td55q8Pob12SEEqcpn0Vw69kjurCY'
    }),
    NgxMaskModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgxPrintModule,
    GalleryModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    CarouselModule,
    AlertModule,
    ProgressbarModule,
    BsDropdownModule,
    AgmCoreModule,
    Ng5SliderModule,
    NgxMaskModule,
    BsDatepickerModule,
    SubmitIfValidDirective,
    AutofocusDirective,
    TruncatePipe,
    SafeHtmlPipe,
    PhoneFormatPipe,
    ModalModule,
    NgxPrintModule,
    GalleryModule
  ]
})
export class SharedModule { }
