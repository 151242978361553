import { Component, OnInit } from '@angular/core';
declare var require: any;
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Yotta Real Property Portal';
  styleURL;
  constructor(private sanitizer: DomSanitizer) { }
  ngOnInit() {
    //require("style-loader!styles/style.css");
    $(function () {
      $(window).scroll(function (event) {
        if ($(this).scrollTop() > 150) {
          $("#menu").fadeIn();
          $("#menu").addClass('fixed');
        }
        else {
          $("#menu").removeClass('fixed')
        }
      });
    });
    this.styleURL = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/styles/style.css?v=' + new Date().toISOString().slice(0, 10));
  }
  getStyleURL() {
    return 
  }
}

