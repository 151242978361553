import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { OnlineFloorplansComponent } from './pages/online-floorplans/online-floorplans.component';
import { OnlineYsfloorplansComponent } from './pages/online-floorplans/online-ysfloorplans.component';
import { NgProgressModule } from '@ngx-progressbar/core';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { UnsubscribeEmailComponent } from './pages/unsubscribe-email/unsubscribe-email';
import { PaperlessSubscriptionComponent } from './pages/paperless-subscription/paperless-subscription.component';
import { SharedModule } from './shared/shared.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { GlobalAPIService } from './services/globalapi.service';
import { GlobalSpinnerService } from './services/global-spinner.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    OnlineFloorplansComponent,
    OnlineYsfloorplansComponent,
    TermsAndConditionsComponent,
    UnsubscribeEmailComponent,
    PaperlessSubscriptionComponent,
    NotFoundComponent,
    RedirectComponent,
   
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientJsonpModule,
    HttpClientModule,
    ToastrModule.forRoot({
      enableHtml: true,
      progressBar: true,
      closeButton: true,
      timeOut: 7500
    }),
    NgProgressModule
  ],
  providers: [GlobalAPIService, GlobalSpinnerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
