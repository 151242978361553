import { Component, OnInit } from "@angular/core";
import { GlobalService } from "../../services/global.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.css"]
})
export class NotFoundComponent implements OnInit {
  allStates = [];
  allRegions = [];
  allDBAs = [];
  companyDtls: any;
  isRedirection = false;
  constructor(
    public apiService: GlobalService,
    private titleService: Title,
    public router: Router,
    private curRoute: ActivatedRoute
  ) {
    this.getInitialData();
    //this.getRegions();
    //this.getDBAs();
  }

  ngOnInit() {
    this.apiService.showPageLoader();
  }
  ngAfterViewInit() {}
  getStates() {
    this.apiService.getData("api/Common/GetAllStates").subscribe(
      result => {
        this.allStates = result;
      },
      err => {}
    );
  }
  getRegions() {
    this.apiService.getData("GetAllRegions").subscribe(
      result => {
        this.allRegions = result;
      },
      err => {}
    );
  }
  getDBAs() {
    this.apiService.getData("GetAllDBAs").subscribe(
      result => {
        this.allDBAs = result;
      },
      err => {}
    );
  }
  getInitialData() {
    this.apiService
      .postData("api/Common/GetManagementDetails", {
        websiteUrl: window.location.href
      })
      .subscribe(
        result => {
          this.companyDtls = result;
          //this.apiService.addMgmtData(this.companyDtls);
          this.titleService.setTitle(this.companyDtls.ManagementCompanyName);
          this.apiService
            .getInitData(this.companyDtls.ManagementCompanyId)
            .subscribe(
              responseList => {
                this.allStates = responseList[0];
                this.allRegions = responseList[1];
                this.allDBAs = responseList[2];
                this.buildRedirectURL();
                // this.buildURLRewrite();
              },
              err => {}
            );
        },
        err => {
          this.apiService.hidePageLoader();
        }
      );
  }
  buildURLRewrite() {
    let params = [];
    let reqDBA = [];
    let reqRegion = [];
    params = this.curRoute.snapshot.url;
    let paramDBAId = 0;
    let IsState = false;
    let stateName = "";
    let isRegion = false;
    let paramDbaName = "";
    let paramRegionName = "";
    let redirectMgmt = false;
    let redirectDBA = false;
    let searchString = "";
    let zipCode = "";
    let paramRegion = "";
    let paramDBA = "";
    let paramState = "";
    if (params.length > 0) {
      params.forEach(g => {
        if (!isNaN(g.path)) {
          zipCode = g.path;
          this.isRedirection = true;
        } else {
          let reqState = this.allStates.filter(d =>
            d.state.toUpperCase().includes(g.path.toUpperCase())
          );
          if (stateName == "" && reqState.length < 1) {
            reqState = this.allStates.filter(d =>
              d.code.toUpperCase().includes(g.path.toUpperCase())
            );
          }
          if (stateName == "" && reqState.length > 0) {
            stateName = reqState[0].state.toUpperCase();
            IsState = true;
            this.isRedirection = true;
            paramState = g.path.toUpperCase();
          }
          reqRegion = this.allRegions.filter(d =>
            d.portalRegion
              .toUpperCase()
              .includes(g.path.toUpperCase().replace(/\%20/gi, " "))
          );
          if (paramRegionName == "" && reqRegion.length > 0) {
            paramRegionName = reqRegion[0].portalRegion.toUpperCase();
            isRegion = true;
            this.isRedirection = true;
            paramRegion = g.path.toUpperCase();
          }
          reqDBA = this.allDBAs.filter(d =>
            d.DBAName.toUpperCase().includes(
              g.path.toUpperCase().replace(/\%20/gi, " ")
            )
          );
          if (reqDBA.length > 0) {
            paramDBAId = +reqDBA[0].DBAId;
            paramDbaName = reqDBA[0].DBAName.toUpperCase();
            this.isRedirection = true;
            paramDBA = g.path.toUpperCase();
          }
        }
      });
      if (paramRegion != paramRegionName) {
        paramRegionName = paramRegion;
      }
      if (paramState != stateName) {
        stateName = paramState;
      }
      if (
        (params.length == 1 &&
          isRegion &&
          paramDBAId > 0 &&
          paramDBA != paramDbaName) ||
        paramDBA != paramDbaName
      ) {
        paramDbaName = "";
        paramDBAId = 0;
      }
      if (
        (paramDBAId > 0 && paramDBA == paramDbaName) ||
        (paramDBAId > 0 &&
          paramDBA == paramDbaName &&
          IsState &&
          isRegion &&
          paramRegion == paramRegionName &&
          paramState == stateName)
      ) {
        redirectDBA = true;
      } else if (paramDbaName == "" && (IsState || isRegion || zipCode != "")) {
        if (paramRegionName != "" && stateName != "" && zipCode != "") {
          searchString = paramRegionName + "," + stateName + "," + zipCode;
        } else if (paramRegionName != "" && stateName != "" && zipCode == "") {
          searchString = paramRegionName + "," + stateName;
        } else if (paramRegionName == "" && stateName != "" && zipCode == "") {
          searchString = stateName;
        } else if (paramRegionName == "" && stateName == "" && zipCode != "") {
          searchString = zipCode;
        } else {
          searchString = paramRegionName;
        }
        redirectMgmt = true;
      } else {
        redirectDBA = false;
      }
      if (redirectDBA) {
        this.router.navigate(["/dba"], {
          queryParams: { dbaid: paramDBAId },
          skipLocationChange: true
        });
      } else if (redirectMgmt) {
        this.router.navigate(
          [
            "/mgmt/dbas-list",
            {
              searchString: searchString
            }
          ],
          { skipLocationChange: true }
        );
      } else {
        this.apiService.hidePageLoader();
        return;
      }
    }
  }
  buildRedirectURL() {
    let regex = /\%20/gi;
    let urlPath = this.router.url
      .substr(1)
      .toUpperCase()
      .replace(regex, " ");
    let urlParts = urlPath.split("/");
    let state = "",
      city = "",
      DBA = "",
      zip = "";
    let dbaId = 0;
    if (
      this.curRoute.snapshot.url.find(u => u.toString().includes(".aspx")) &&
      (this.curRoute.snapshot.queryParamMap.has("Id") ||
        this.curRoute.snapshot.queryParamMap.has("ID") ||
        this.curRoute.snapshot.queryParamMap.has("id"))
    ) {
      dbaId = this.curRoute.snapshot.queryParamMap.get("Id")
        ? +this.curRoute.snapshot.queryParamMap.get("Id")
        : this.curRoute.snapshot.queryParamMap.get("ID")
        ? +this.curRoute.snapshot.queryParamMap.get("ID")
        : this.curRoute.snapshot.queryParamMap.get("id")
        ? +this.curRoute.snapshot.queryParamMap.get("id")
        : 0;
      if (this.allDBAs.filter(d => d.DBAId == dbaId).length > 0) {
        this.isRedirection = true;
        this.router.navigate(["/dba"], { queryParams: { dbaid: dbaId } });
      }
    } else {
      this.buildURLRewrite();
    }
    if (!this.isRedirection) {
      this.apiService.hidePageLoader();
    }
  }
}
